<template>
  <div>
    <div class="hero">
      <div class="hero__container">
        <h1>Join the team</h1>
        <p>
          The world’s most innovative companies are customer-obsessed. We’re
          building the customer data platform to make that possible.
        </p>
        <a class="btn" href="#openRoles">See open roles</a>
      </div>
    </div>

    <div class="overview">
      <h1>
        We’re building an ecosystem for modern legal firms in Africa and beyond
      </h1>
      <p>
        LawPavilion’s mission is to create a workplace where work empowers
        people. Winning companies put people first. If you focus on recruiting,
        cultivating, and retaining good people, you’ll see better results. This
        is the fundamental belief in LawPavilion.
      </p>

      <div class="overview__img">
        <img svg-inline alt="logo" src="@/assets/img/career/overview1.png" />
        <img svg-inline alt="logo" src="@/assets/img/career/overview2.png" />
        <img svg-inline alt="logo" src="@/assets/img/career/overview3.png" />
        <img svg-inline alt="logo" src="@/assets/img/career/overview4.png" />
        <img svg-inline alt="logo" src="@/assets/img/career/overview5.png" />
        <img svg-inline alt="logo" src="@/assets/img/career/overview6.png" />
        <img svg-inline alt="logo" src="@/assets/img/career/overview7.png" />
      </div>
    </div>

    <div class="benefits">
      <div class="benefits__container">
        <div class="benefits__header">
          <h1>Benefits</h1>
          <p>
            LawPavilion’s mission is to create a workplace where work empowers
            people. Winning companies put people first.
          </p>
        </div>
        <div class="benefits__list">
          <div class="benefits__list--item">
            <section class="pale__pink">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M23.112 5.165a3.026 3.026 0 000-4.279 3.094 3.094 0 00-4.276 0L16.4 3.324c-1.012 1.013-3.123.789-4.58-.013a2.6 2.6 0 00-3.056 4.144l1.416 1.416-8.155 8.154a3.455 3.455 0 00-.593 4.129l-1.14 1.139a1 1 0 001.415 1.414l1.139-1.139a3.455 3.455 0 004.129-.593l8.154-8.155 1.416 1.416a2.6 2.6 0 004.144-3.057c-.8-1.456-1.025-3.568-.013-4.58l2.436-2.434zM5.56 20.561a1.536 1.536 0 01-2.121 0 1.5 1.5 0 010-2.121l8.155-8.154 2.12 2.121-8.154 8.154zm13.7-14.376c-1.76 1.759-1.5 4.832-.325 6.958a.578.578 0 01-.12.679.621.621 0 01-.858 0l-7.78-7.781A.612.612 0 0110.607 5c.087 0 .174.021.25.063 2.127 1.17 5.2 1.434 6.959-.325L20.254 2.3a1.045 1.045 0 011.446 0 1.025 1.025 0 010 1.449l-2.44 2.436z" fill="#FF7A00"/></svg>
            </section>
            <h3>Comprehensive Health Package</h3>
          </div>

          <div class="benefits__list--item">
            <section class="pale__green">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M16.5 17a3.5 3.5 0 00-3.235 2.174 4.685 4.685 0 00-2.53 0 3.494 3.494 0 10.193 2.033A3.52 3.52 0 0112 21c.366.013.728.083 1.072.207A3.5 3.5 0 1016.5 17zm-9 5a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm9 0a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm3.475-13.325C19.709.612 15.3.026 15.105.006A.924.924 0 0015 0a4.509 4.509 0 00-2.416.818 1.018 1.018 0 01-1.167 0C10.707.318 9.867.035 9 0a.924.924 0 00-.1.006c-.194.02-4.6.606-4.87 8.669C2.693 9.122 0 10.243 0 12c0 3.69 6.194 4 12 4s12-.31 12-4c0-1.757-2.693-2.878-4.025-3.325zm-10.92-6.67c.436.047.854.202 1.215.452a3.026 3.026 0 003.461 0 2.64 2.64 0 011.206-.452c.445.106 2.909.964 3.056 6.858A13.208 13.208 0 0112 10a13.217 13.217 0 01-5.993-1.136C6.155 2.92 8.658 2.1 9.055 2.005zM12 14c-8.265 0-10-1.088-10.012-1.918a6.04 6.04 0 012.86-1.574A14.558 14.558 0 0012 12a14.557 14.557 0 007.152-1.492A6.83 6.83 0 0122 12c0 .912-1.735 2-10 2z" fill="#FF7A00"/></svg>
            </section>
            <h3>Work-from-home Stipend</h3>
          </div>

          <div class="benefits__list--item">
            <section class="pale__blue">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g clip-path="url(#clip0)"><path d="M20 0a4 4 0 00-4 4 1 1 0 002 0 2 2 0 114 0v11.026A4.948 4.948 0 0019 14a5 5 0 00-4.145 2.207 3.982 3.982 0 00-5.71 0A5 5 0 005 14a4.948 4.948 0 00-3 1.026V4a2 2 0 114 0 1 1 0 002 0 4 4 0 00-8 0v15a5 5 0 1010 0 2 2 0 014 0 5 5 0 1010 0V4a4 4 0 00-4-4zM5 22a3 3 0 110-6 3 3 0 010 6zm14 0a3 3 0 110-5.999A3 3 0 0119 22z" fill="#FF7A00"/></g><defs><clipPath id="clip0"><path fill="#fff" d="M0 0h24v24H0z"/></clipPath></defs></svg>
            </section>
            <h3>Learning and Development</h3>
          </div>

          <div class="benefits__list--item">
            <section class="pale__purple">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g clip-path="url(#clip0)"><path d="M7.5 13a4.5 4.5 0 110-9 4.5 4.5 0 010 9zm0-7a2.5 2.5 0 100 5 2.5 2.5 0 000-5zM15 23v-.5a7.5 7.5 0 00-15 0v.5a1 1 0 102 0v-.5a5.5 5.5 0 0111 0v.5a1 1 0 002 0zm9-5a7 7 0 00-11.667-5.217 1 1 0 101.334 1.49A5 5 0 0122 18a1 1 0 002 0zm-6.5-9a4.5 4.5 0 110-9 4.5 4.5 0 010 9zm0-7a2.5 2.5 0 100 5 2.5 2.5 0 000-5z" fill="#FF7A00"/></g><defs><clipPath id="clip0"><path fill="#fff" d="M0 0h24v24H0z"/></clipPath></defs></svg>
            </section>
            <h3>Generous Parental and Family Leave</h3>
          </div>

          <div class="benefits__list--item">
            <section class="pale__purple">
              <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M15 14a1 1 0 01-1 1H6a1 1 0 110-2h8a1 1 0 011 1zm-4 3H6a1 1 0 000 2h5a1 1 0 100-2zm9-6.515V19a5.006 5.006 0 01-5 5H5a5.006 5.006 0 01-5-5V5a5.006 5.006 0 015-5h4.515a6.958 6.958 0 014.95 2.05l3.484 3.486A6.95 6.95 0 0120 10.485zm-6.949-7.021A5.01 5.01 0 0012 2.684V7a1 1 0 001 1h4.316a4.98 4.98 0 00-.781-1.05l-3.484-3.486zM18 10.485c0-.165-.032-.323-.047-.485H13a3 3 0 01-3-3V2.047C9.838 2.032 9.679 2 9.515 2H5a3 3 0 00-3 3v14a3 3 0 003 3h10a3 3 0 003-3v-8.515z" fill="#FF7A00"/></svg>
            </section>
            <h3>Flexible PTO Policy</h3>
          </div>

          <div class="benefits__list--item">
            <section class="pale__green">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g clip-path="url(#clip0)"><path d="M7.5 13a4.5 4.5 0 110-9 4.5 4.5 0 010 9zm0-7a2.5 2.5 0 100 5 2.5 2.5 0 000-5zM15 23v-.5a7.5 7.5 0 00-15 0v.5a1 1 0 102 0v-.5a5.5 5.5 0 0111 0v.5a1 1 0 002 0zm9-5a7 7 0 00-11.667-5.217 1 1 0 101.334 1.49A5 5 0 0122 18a1 1 0 002 0zm-6.5-9a4.5 4.5 0 110-9 4.5 4.5 0 010 9zm0-7a2.5 2.5 0 100 5 2.5 2.5 0 000-5z" fill="#FF7A00"/></g><defs><clipPath id="clip0"><path fill="#fff" d="M0 0h24v24H0z"/></clipPath></defs></svg>
            </section>
            <h3>Family Building Benefits</h3>
          </div>

          <div class="benefits__list--item">
            <section class="pale__purple">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g clip-path="url(#clip0)" fill="#FF7A00"><path d="M19 0H5a5.006 5.006 0 00-5 5v14a5.006 5.006 0 005 5h14a5.006 5.006 0 005-5V5a5.006 5.006 0 00-5-5zM7 22v-1a5 5 0 1110 0v1H7zm15-3a3 3 0 01-3 3v-1a7 7 0 10-14 0v1a3 3 0 01-3-3V5a3 3 0 013-3h14a3 3 0 013 3v14z"/><path d="M12 4a4 4 0 100 8 4 4 0 000-8zm0 6a2 2 0 110-4 2 2 0 010 4z"/></g><defs><clipPath id="clip0"><path fill="#fff" d="M0 0h24v24H0z"/></clipPath></defs></svg>
            </section>
            <h3>Paid Volunteer Time Off</h3>
          </div>

          <div class="benefits__list--item">
            <section class="pale__purple">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M19 1H5a5.006 5.006 0 00-5 5v12a5.006 5.006 0 005 5h14a5.006 5.006 0 005-5V6a5.006 5.006 0 00-5-5zM5 3h14a3 3 0 013 3v1H2V6a3 3 0 013-3zm14 18H5a3 3 0 01-3-3V9h20v9a3 3 0 01-3 3zm0-8a1 1 0 01-1 1H6a1 1 0 010-2h12a1 1 0 011 1zm-4 4a1 1 0 01-1 1H6a1 1 0 010-2h8a1 1 0 011 1zM3 5a1 1 0 112 0 1 1 0 01-2 0zm3 0a1 1 0 112 0 1 1 0 01-2 0zm3 0a1 1 0 112 0 1 1 0 01-2 0z" fill="#FF7A00"/></svg>
            </section>
            <h3>Gym Membership</h3>
          </div>
        </div>
      </div>
    </div>

    <div class="values">
      <div class="values__container">
        <div class="values__header">
          <h1>Values We Respect</h1>
          <p>
            LawPavilion’s mission is to create a workplace where work empowers
            people. Winning companies put people first.
          </p>
        </div>
        <div class="values__media">
          <video-player :options="videoOptions" />
        </div>
        <Values theme="light" />
      </div>
    </div>

    <div class="job openings" id="openRoles">
      <div class="job__container">
        <div class="job__header">
          <h1>Job Openings</h1>
          <p>
            LawPavilion’s mission is to create a workplace where work empowers
            people. Winning companies put people first.
          </p>
        </div>
        <div
          class="openings__item"
          v-for="(opening, index) in openings"
          :key="index"
        >
          <div class="header">
            <p class="location">
              <span v-for="(location, index) in opening.location" :key="index">
                {{ location }}, &nbsp;
              </span>
            </p>
            <p class="job-type">{{ opening.job_type }}</p>
          </div>
          <div class="body">
            <p class="job-title">{{ opening.title }}</p>
            <div class="pills">
              <div
                :class="{
                  pills__item: true,
                  'pills__item--outline': index > 0,
                }"
                v-for="(tab, index) in opening.tabs"
                :key="index"
              >
                {{ tab }}
              </div>
            </div>
          </div>
          <p class="text">
            Qualified candidates should send CVs to
            <a :href="opening.emailstring">careers@lawpavilion.com</a>
            using the role they are applying for as the subject of the e-mail
          </p>
        </div>
      </div>
    </div>
    <!-- <div class="openings"></div> -->
  </div>
</template>

<script>
import Values from "@/components/Values";
import VideoPlayer from "@/components/VideoPlayer.vue";
export default {
  name: "Career",
  components: {
    Values,
    VideoPlayer,
  },
  data() {
    return {
      videoOptions: {
        autoplay: false,
        fluid: true,
        controls: true,
        techOrder: ["youtube"],
        sources: [
          {
            src: "https://www.youtube.com/watch?v=tAemDNUdFsQ",
            type: "video/youtube",
            youtube: { iv_load_policy: 1 },
          },
        ],
      },
      openings: [
        {
          location: ["Lagos", "Warri", "Delta", "Jos"],
          job_type: "Permanent",
          title: "Marketing Executives",
          tabs: ["Marketing"],
          emailstring:
            "mailto:careers@lawpavilion.com?subject=Marketing%20Executives",
        },
        {
          location: ["Lagos"],
          job_type: "Permanent",
          title: "Legal Officers",
          tabs: ["Legal"],
          emailstring:
            "mailto:careers@lawpavilion.com?subject=Legal%20Officers",
        },
        {
          location: ["Lagos"],
          job_type: "Permanent",
          title: "Strategic Industry Engagement Officers",
          tabs: ["Engagement"],
          emailstring:
            "mailto:careers@lawpavilion.com?subject=Strategic%20Industry%20Engagement%20Officers",
        },
        {
          location: ["Remote"],
          job_type: "Permanent",
          title: "DevOps",
          tabs: ["Engineering", "Docker", "Dokku", "GitLab CI"],
          emailstring: "mailto:careers@lawpavilion.com?subject=DevOps",
        },
        {
          location: ["Remote"],
          job_type: "Permanent",
          title: "Senior Backend Developer",
          tabs: ["Engineering", "PHP/Larave", "MySQL", "Docker", "Nginx"],
          emailstring:
            "mailto:careers@lawpavilion.com?subject=Senior%20Backend%20Developer",
        },
        {
          location: ["Remote"],
          job_type: "Permanent",
          title: "Junior Developers/Interns",
          tabs: ["Engagement"],
          emailstring:
            "mailto:careers@lawpavilion.com?subject=Junior%20Developers/Interns",
        },
        {
          location: ["Remote"],
          job_type: "Permanent",
          title: "Intermediate Designer",
          tabs: ["Design", "Figma"],
          emailstring:
            "mailto:careers@lawpavilion.com?subject=Intermediate%20Designer",
        },
        {
          location: ["Remote"],
          job_type: "Permanent",
          title: "Intermediate/Snr Java Developer",
          tabs: ["Engineering", "Java"],
          emailstring:
            "mailto:careers@lawpavilion.com?subject=Intermediate/Snr%20Java%20Developer",
        },
        {
          location: ["Remote"],
          job_type: "Permanent",
          title: "Junior Java Developer",
          tabs: ["Engineering", "Java"],
          emailstring:
            "mailto:careers@lawpavilion.com?subject=Junior%20Java%20Developer",
        },
        {
          location: ["Remote"],
          job_type: "Permanent",
          title: "Senior/Intermediate Front End Developer",
          tabs: ["Engineering", "Angular"],
          emailstring:
            "mailto:careers@lawpavilion.com?subject=Senior/Intermediate%20Front%20End%20Developer",
        },
        {
          location: ["Remote"],
          job_type: "Permanent",
          title: "Senior/Intermediate Back End Developer",
          tabs: ["Engineering", "C#", ".Net"],
          emailstring:
            "mailto:careers@lawpavilion.com?subject=Senior/Intermediate%20Back%20End%20Developer",
        },
        {
          location: ["Remote"],
          job_type: "Permanent",
          title: "Intermediate Android Developer",
          tabs: ["Engineering", "Java", "Kotlin"],
          emailstring:
            "mailto:careers@lawpavilion.com?subject=Senior%20Android%20Developer",
        },
        {
          location: ["Remote"],
          job_type: "Permanent",
          title: "Senior/Intermediate IOS Developer",
          tabs: ["Engineering", "Swift"],
          emailstring:
            "mailto:careers@lawpavilion.com?subject=Senior/Intermediate%20IOS%20Developer",
        },
        {
          location: ["Remote"],
          job_type: "Permanent",
          title: "Data Scientist",
          tabs: ["Engineering", "Tensorflow", "NLP", "Git"],
          emailstring:
            "mailto:careers@lawpavilion.com?subject=Data%20Scientist",
        },
      ],
    };
  },
  methods: {
    goToApply(id) {
      this.$router.push({ name: "career-details", params: { id } });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./Career.component.scss";
</style>
